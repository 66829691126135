var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        darkmode: _vm.isDarkMode,
        screensaverActive: _vm.screensaverActive
      },
      attrs: { id: "app" }
    },
    [
      _vm.viewportRedrawEnforcerVisible
        ? _c("div", { attrs: { id: "viewportRedrawEnforcer" } })
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "bg" } },
        [
          _c(
            "transition",
            { attrs: { name: _vm.transitionName } },
            [_c("router-view", { on: { statusChange: _vm.statusHasChanged } })],
            1
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.showStatus && _vm.status
            ? _c("status-pop-up", {
                attrs: {
                  isError: _vm.status.isError,
                  message: _vm.status.message
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }