var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "player" } },
    [
      _c("video-player", {
        ref: "videoplayer",
        staticClass: "videoplayer",
        attrs: { src: _vm.src, chapters: _vm.chapters, angle: _vm.angle },
        on: {
          chapterChange: _vm.chapterChangeHandler,
          timeupdate: _vm.handleTimeUpdate,
          playstatechange: _vm.handlePlayPause,
          videoloaded: _vm.handleVideoLoaded,
          uidisplaychange: _vm.handleUIDisplay,
          zoomchange: _vm.handleZoomChange,
          videoerror: _vm.handleVideoLoadingError
        }
      }),
      _c("info-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTimeline && !_vm.showChapters,
            expression: "showTimeline && !showChapters"
          }
        ],
        staticClass: "info-bar",
        attrs: {
          title: _vm.production.name,
          date: _vm.performance.dateString,
          angle: _vm.angle.name
        }
      }),
      _c(
        "h2",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTimeline,
              expression: "showTimeline"
            }
          ],
          staticClass: "ui-text-info"
        },
        [_vm._v("Zoom: " + _vm._s(_vm.currentZoom) + "%")]
      ),
      _c(
        "div",
        {
          ref: "chaptertouch",
          staticClass: "touch-area chapters",
          on: {
            click: function($event) {
              _vm.showChapters = true
            }
          }
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showChapters,
                expression: "!showChapters"
              }
            ],
            staticStyle: { position: "absolute", top: "50%", left: "10px" },
            attrs: { src: require("@/assets/img/points_mod.svg") }
          })
        ]
      ),
      _c(
        "div",
        {
          ref: "timelinetouch",
          staticClass: "touch-area timeline-bar",
          on: {
            click: function($event) {
              _vm.showTimeline = true
            }
          }
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showTimeline,
                expression: "!showTimeline"
              }
            ],
            staticStyle: {
              poistion: "absolute",
              bottom: "10px",
              transform: "rotate(90deg)"
            },
            attrs: { src: require("@/assets/img/points_mod.svg") }
          })
        ]
      ),
      _c("router-link", { attrs: { to: "/" } }, [
        _c("div", { ref: "hometouch", staticClass: "touch-area home" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTimeline,
                expression: "showTimeline"
              }
            ],
            attrs: { src: require("@/assets/img/home.svg") }
          })
        ])
      ]),
      _c(
        "transition",
        { attrs: { name: "chapterslide" } },
        [
          _c("chapter-tab", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showChapters,
                expression: "showChapters"
              }
            ],
            ref: "chaptertab",
            attrs: { chapters: _vm.chapters, isShowing: _vm.showChapters },
            on: {
              chapterJump: _vm.chapterJumpHandler,
              close: function($event) {
                _vm.showChapters = false
              }
            }
          })
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "barslide" } },
        [
          _c("control-bar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTimeline,
                expression: "showTimeline"
              }
            ],
            staticClass: "control-bar",
            attrs: {
              chapters: _vm.chapters,
              production: _vm.production,
              angle: _vm.angle,
              performance: _vm.performance,
              position: _vm.videotime,
              isPlaying: _vm.videoIsPlaying
            },
            on: {
              setposition: _vm.handleSetPosition,
              loclast: _vm.handleLocLast,
              setvolume: _vm.handleSetVolume,
              setrate: _vm.handleSetRate,
              play: _vm.setPlay,
              pause: _vm.setPause
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "nav" }),
      _vm.showSpinner ? _c("Spinner") : _vm._e(),
      _c("router-link", { attrs: { to: "/select" } }, [
        _vm.showErrorOverlay
          ? _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  "background-color": "grey",
                  position: "absolute",
                  top: "0px",
                  left: "0px"
                }
              },
              [
                _c("h2", [
                  _vm._v(
                    "\n        Seems like the video you requested is not available "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        you requested: " +
                      _vm._s(_vm.production.name) +
                      " | " +
                      _vm._s(_vm.performance.dateString) +
                      " |\n        " +
                      _vm._s(_vm.angle.name) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Please speak to an admin to get the necessary files transferred to\n        your device.\n      "
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _c("audio", {
        ref: "wakeUpSound",
        staticStyle: { display: "none" },
        attrs: { controls: "", src: "/audio/Flubb.mp3", preload: "auto" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }