var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("center", [
        _c("div", { staticClass: "credentials" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.username,
                expression: "username"
              }
            ],
            staticClass: "input",
            attrs: {
              id: "user",
              placeholder: "Username",
              type: "input",
              "data-layout": "compact"
            },
            domProps: { value: _vm.username },
            on: {
              focus: _vm.show,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.username = $event.target.value
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            staticClass: "input",
            attrs: {
              id: "pw",
              type: "password",
              placeholder: "Password",
              "data-layout": "compact"
            },
            domProps: { value: _vm.password },
            on: {
              focus: _vm.show,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          }),
          _c(
            "div",
            {
              staticClass: "log-in-button",
              staticStyle: { float: "left" },
              on: { click: _vm.loginUser }
            },
            [
              _c("log-in-icon", {
                attrs: { size: "5x", color: "white", "stroke-width": "0.5" }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.defaultVisible,
                  expression: "defaultVisible"
                }
              ],
              staticClass: "log-in-button",
              staticStyle: { float: "right" },
              on: { click: _vm.loginDefault }
            },
            [_c("h2", [_vm._v("Default Login")])]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "keys" },
        [
          _vm.visible && _vm.isWindows
            ? _c("vue-touch-keyboard", {
                attrs: {
                  options: _vm.options,
                  layout: _vm.layout,
                  cancel: _vm.hide,
                  accept: _vm.accept,
                  input: _vm.input
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "login-logo-area" } }, [
      _c("img", {
        staticClass: "logo",
        staticStyle: { height: "100%" },
        attrs: { src: require("@/assets/img/logo_2024_blue_german.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }