var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selection-screen" }, [
    _c("div", { attrs: { id: "logo-area" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/img/logo_2024_white_german.svg") }
      }),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            right: "2vw",
            top: "2vw",
            height: "2vw"
          }
        },
        [
          _vm.syncIsRunning
            ? _c("img", {
                staticClass: "spinner",
                staticStyle: { position: "absolute", left: "-50px" },
                attrs: { src: require("@/assets/img/spinner.svg") }
              })
            : _vm._e(),
          _c("img", {
            staticStyle: { height: "100%" },
            attrs: { src: require("@/assets/img/aburgermenu.svg") },
            on: { click: _vm.handleMenu }
          }),
          _vm.menuIsVisible
            ? _c("div", { attrs: { id: "dropdown" } }, [
                _c(
                  "div",
                  {
                    staticClass: "menu-item",
                    class: { syncActive: _vm.syncIsRunning },
                    on: { click: _vm.startSync }
                  },
                  [_vm._v("\n          Sync\n        ")]
                ),
                _vm.$store.state.loginStatus.isLoggedIn
                  ? _c(
                      "div",
                      {
                        staticClass: "menu-item",
                        on: { click: _vm.gotoLogout }
                      },
                      [_vm._v("\n          Logout\n        ")]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "menu-item",
                        on: { click: _vm.gotoLogin }
                      },
                      [_vm._v("Login")]
                    ),
                _vm.syncIsRunning && _vm.syncProgress
                  ? _c("div", [_vm._v(_vm._s(_vm.syncProgress))])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ]),
    _c("div", { staticClass: "box-container" }, [
      _c("h1", { staticClass: "production header" }, [_vm._v("BALLET")]),
      _c(
        "div",
        { staticClass: "select-box-container production" },
        [
          _c("SelectBox", {
            staticClass: "select-box",
            attrs: {
              selectionFromParent: _vm.selectedProduction,
              listItems: _vm.productions,
              displayProperty: "name"
            },
            on: { change: _vm.selectProduction }
          })
        ],
        1
      ),
      _c("h1", { staticClass: "date header" }, [_vm._v("DATE")]),
      _c(
        "div",
        { staticClass: "select-box-container date" },
        [
          _vm.selectedProduction
            ? _c("SelectBox", {
                staticClass: "select-box",
                attrs: {
                  selectionFromParent: _vm.selectedPerformance,
                  listItems: _vm.performances,
                  displayProperty: "dateString"
                },
                on: { change: _vm.selectPerformance }
              })
            : _vm._e()
        ],
        1
      ),
      _c("h1", { staticClass: "view header" }, [_vm._v("VIEW")]),
      _c(
        "div",
        { staticClass: "select-box-container view" },
        [
          _vm.selectedPerformance
            ? _c("SelectBox", {
                staticClass: "select-box",
                attrs: {
                  selectionFromParent: _vm.selectedAngle,
                  listItems: _vm.angles,
                  displayProperty: "name"
                },
                on: { change: _vm.selectAngle }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "preview-border" }, [
      _vm.selectedProduction && _vm.selectedPerformance && _vm.selectedAngle
        ? _c("div", { staticClass: "preview-area" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showThumbnailSpinner,
                  expression: "!showThumbnailSpinner"
                }
              ],
              staticClass: "preview-thumbnail no_darkmode",
              attrs: { src: _vm.thumbnailUrl, width: "200", height: "150" },
              on: { error: _vm.showErrorImage }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showThumbnailSpinner,
                    expression: "showThumbnailSpinner"
                  }
                ],
                staticClass: "preview-thumbnail",
                staticStyle: { width: "300px" }
              },
              [
                _c("img", {
                  staticClass: "no_darkmode spinner",
                  staticStyle: {
                    width: "80px",
                    position: "absolute",
                    top: "60px",
                    left: "120px"
                  },
                  attrs: { src: require("@/assets/img/spinner.svg") }
                })
              ]
            ),
            _c("div", { staticClass: "meta-box" }, [
              _c("div", { staticClass: "meta-header" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.selectedProduction.name.toUpperCase()) +
                    " ||\n          " +
                    _vm._s(_vm.selectedPerformance.dateString.toUpperCase()) +
                    " ||\n          " +
                    _vm._s(_vm.selectedAngle.name.toUpperCase()) +
                    "\n        "
                )
              ]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.selectedPerformance.metaInfo) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "go-button", on: { mousedown: _vm.goToVideo } },
              [
                _c("img", {
                  staticStyle: { height: "100%" },
                  attrs: { src: require("@/assets/img/go_play.svg") }
                })
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }