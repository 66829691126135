var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "videoplayer" } }, [
    _c(
      "div",
      { ref: "touchLayer", staticStyle: { width: "100%", height: "100%" } },
      [
        _c("video", {
          ref: "vid",
          staticClass: "no_darkmode",
          style: {
            transform: _vm.videoTransform,
            filter:
              "contrast(" +
              _vm.contrast +
              "%) brightness(" +
              _vm.brightness +
              ")"
          },
          attrs: {
            id: "video",
            crossOrigin: "anonymous",
            playsinline: "true",
            preload: "auto",
            autoplay: "true"
          },
          on: {
            timeupdate: _vm.videoTimeUpdated,
            play: function($event) {
              return _vm.handlePlaystateChange(true)
            },
            pause: function($event) {
              return _vm.handlePlaystateChange(false)
            },
            error: _vm.handleLoadingError,
            loadedmetadata: _vm.handleLoadingSuccess
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }