import { normalize, schema } from "normalizr";

// Define a users schema
const video = new schema.Entity("videos");

const chapter = new schema.Entity("chapters");

const videoSetting = new schema.Entity("settings");

const cameraAngle = new schema.Entity("cameraAngles", {
  settings: videoSetting,
  videos: [video],
});

const performance = new schema.Entity("performances", {
  cameraAngles: [cameraAngle],
  chapters: [chapter],
});

const production = new schema.Entity("productions", {
  performances: [performance],
});

const balletSchema = [production];

export function normalizeData(originalData: any) {
  return normalize(originalData, balletSchema);
}
