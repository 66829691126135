var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "info-container" } }, [
    _c("h2", [
      _vm._v(
        _vm._s(_vm.title) + " | " + _vm._s(_vm.date) + " | " + _vm._s(_vm.angle)
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }