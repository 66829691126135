
















































































































import Vue from "vue";
import { Production, Performance, CameraAngle, FormattedDate } from "@/models";
import SelectBox from "@/components/SelectBox.vue";
import { SelectionScreenComponent } from "@/views/TestInterfaces";
import moment from "moment";
import { normalizeData } from "@/normalizr";
import localForage from "localforage";
import productionData from "@/assets/dummyData.json";

export default Vue.extend({
  data() {
    return {
      selectedProduction: null as Production | null,
      selectedPerformance: null as Performance | null,
      selectedAngle: null as CameraAngle | null,
      syncIsRunning: false as boolean,
      syncProgress: null as string | null,
      pollInterval: null as any | null,
      syncInterval: null as any | null,
      syncNotRunningCounter: 0 as number,
      menuIsVisible: false as boolean,
      thumbnailUrl: "" as String,
      isOfflineMode: false as boolean,
      showThumbnailSpinner: false as boolean,
    };
  },
  name: "SelectionScreen",

  components: {
    SelectBox,
  },

  created: function () {
    // if (!this.$store.state.homepageInitialized) {
    //   this.loadAvailableProductions();
    //   this.startRecurringUpdateTimer();
    //   this.$store.commit("setHomepageInitialization", true);
    // } else if (
    //   this.productions.length < 1
    // ) {
    //   this.loadAvailableProductions();
    //   this.startRecurringUpdateTimer();
    // }
    this.loadAvailableProductions();
    this.startRecurringUpdateTimer();
  },
  mounted: function () {
    this.setPreviousSelections(
      this.$store.state.selected.production,
      this.$store.state.selected.performance,
      this.$store.state.selected.angle
    );
  },

  activated: function () {
    this.syncNotRunningCounter = 0;
    this.loadAvailableProductions();

    this.$store.commit("resetSettings"); // Reset Contrast and Brightness for angles
    this.startProgressPolling(); // check to see if there is already an active sync running
    this.selectedProduction = null;
  },

  deactivated: function () {
    if (this.pollInterval) clearInterval(this.pollInterval);
  },

  computed: {
    productions(): Array<Production> {
      let tempProductions: Array<Production> = new Array<Production>();
      for (let production in this.$store.state.entities.productions) {
        var pc = this.$store.state.entities.productions[production];
        if (pc) tempProductions.push(pc);
      }
      tempProductions.sort((a: Production, b: Production) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
      return tempProductions;
    },

    performances(): Array<Performance> {
      let tempPerformances: Array<Performance> = new Array<Performance>();
      if (this.selectedProduction != null) {
        this.selectedProduction.performances.forEach((performanceId) => {
          let performance =
            this.$store.state.entities.performances[performanceId];
          if (performance) {
            performance.dateString = moment(new Date(performance.date)).format(
              "DD.MM.YYYY"
            );
            if (performance.suffix && performance.suffix !== "") {
              performance.dateString += `-${performance.suffix}`;
            }
            tempPerformances.push(performance);
          }
        });
      }
      return this.sortByDatesAndSuffix(tempPerformances);
    },

    angles(): Array<CameraAngle> {
      let angles: Array<CameraAngle> = new Array<CameraAngle>();
      if (this.selectedPerformance != null) {
        this.selectedPerformance.cameraAngles.forEach((angleId) => {
          let angle = this.$store.state.entities.cameraAngles[angleId];
          if (angle) angles.push(angle);
        });
      }
      return angles;
    },
  },
  watch: {
    selectedAngle(to, from) {
      if (to != null) {
        this.setThumbnailUrl(to);
      }
    },
  },
  filters: {},

  methods: {
    getAvailablePath(url: any, fileUrl: any) {
      return new Promise(function (resolve, reject) {
        // console.log("fetch with timeout");
        window
          .fetchWithTimeout(url + fileUrl, { method: "HEAD" }, 300)
          .then((res) => {
            if (res.status == 200) resolve(url + fileUrl);
            reject({ status: res.status });
          })
          .catch((err) => {
            reject({ error: err });
          });
      });
    },

    setThumbnailUrl(angle: any) {
      this.showThumbnailSpinner = true;
      this.thumbnailUrl = "";
      if (angle.previewImage != null) {
        let url = angle.previewImage.replace(
          this.$store.state.networkConfig.altBaseUrl,
          ""
        );
        this.getAvailablePath(this.$store.state.fileBaseUrl, url)
          .then((path) => (this.thumbnailUrl = path as String))
          .catch((err) => {
            console.log(err);
            this.thumbnailUrl = this.$store.state.networkConfig.baseUrl + url;
          })
          .finally(() => {
            this.showThumbnailSpinner = false;
          });
      } else {
        this.thumbnailUrl = "";
        this.showThumbnailSpinner = false;
      }
    },

    fetchProductionsAndCommit(token: String = "") {
      if (!token || token == "") {
        token = this.$store.state.deviceToken;
        let storedDefaultProductions = localForage.getItem("productions");
        if (storedDefaultProductions) {
          this.$store.commit("setEntities", storedDefaultProductions);
          this.$emit("statusChange", {
            isError: false,
            message: "Currently in offline mode",
          });
          this.isOfflineMode = true;
        } else
          this.$emit("statusChange", {
            isError: true,
            message: "no production data found on device",
          });
      } else {
        fetch(
          this.$store.state.networkConfig.apiUrl +
            this.$store.state.networkConfig.productionsEndpoint,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        )
          .then((resp) => {
            if (!resp.ok) {
              throw Error(resp.statusText);
            }
            return resp.json();
          })
          .catch((err) => {
            this.$emit("statusChange", {
              isError: true,
              message: "Could not get productions: " + err,
            });
          })
          .then((data) => {
            let normalizedData = normalizeData(data);

            /* if (Object.keys(normalizedData.entities.videos).length > 500) {
              this.$store.state.entities = normalizedData.entities; // is not vuex standard but prevents saving the data to localStorage
              console.warn(
                "Cannot store list bigger than 500 videos persistently"
              );
            } else { */
            this.$store.commit("setEntities", normalizedData.entities);
            //}

            this.deselectIfSelectedElementsNotExisting();
            this.isOfflineMode = false;

            if (
              this.$store.state.loginStatus.username ===
              this.$store.state.defaultUser.username
            ) {
              localForage.setItem(
                "productions",
                JSON.stringify(normalizedData.entities)
              );
            }
          });
      }
    },

    deselectIfSelectedElementsNotExisting() {
      let productionStillExists = false;
      let performanceStillExists = false;
      let angleStillExists = false;
      if (this.selectedProduction) {
        productionStillExists =
          typeof this.$store.state.entities.productions != "undefined" &&
          this.selectedProduction.id in this.$store.state.entities.productions;

        if (this.selectedPerformance) {
          performanceStillExists =
            typeof this.$store.state.entities.performances != "undefined" &&
            this.selectedPerformance.id in
              this.$store.state.entities.performances;

          if (this.selectedAngle) {
            angleStillExists =
              typeof this.$store.state.entities.cameraAngles != "undefined" &&
              this.selectedAngle.id in this.$store.state.entities.cameraAngles;
          }
        }
      }

      if (!productionStillExists) {
        this.selectProduction(this.productions[0]);
      }

      //If the selected production still exists but the perfomance does not, the selected production is also
      //outdated and must be recalculated from new data
      else if (!performanceStillExists) {
        for (let i = 0; i < this.productions.length; i++) {
          if (
            this.selectedProduction != null &&
            this.selectedProduction.id == this.productions[i].id
          ) {
            this.selectProduction(this.productions[i]);
            break;
          }
        }
      }
      //If the selected production and performace still exist but the angle does not, the selected production and performance are also
      //outdated and must be recalculated from new data
      else if (!angleStillExists) {
        //this.selectedAngle = null;
        for (let i = 0; i < this.productions.length; i++) {
          if (
            this.selectedProduction != null &&
            this.selectedProduction.id == this.productions[i].id
          ) {
            this.selectProduction(this.productions[i]);
            for (let i = 0; i < this.performances.length; i++) {
              if (
                this.selectedPerformance != null &&
                this.selectedPerformance.id == this.performances[i].id
              ) {
                this.selectPerformance(this.performances[i]);
                break;
              }
            }
            break;
          }
        }
      }
    },

    loadAvailableProductions() {
      if (this.$store.state.loginStatus.isLoggedIn) {
        this.fetchProductionsAndCommit(this.$store.state.loginStatus.userToken);
      }
    },

    setPreviousSelections(
      production: Production | null,
      performance: Performance | null,
      angle: CameraAngle | null
    ) {
      this.selectedProduction = production;
      this.selectedPerformance = performance;
      this.selectedAngle = angle;
    },

    selectProduction(production: Production | null): void {
      this.selectedProduction = production;
      this.selectPerformance(this.performances[0]);
    },

    selectPerformance(performance: Performance | null): void {
      this.selectedPerformance = performance;
      this.selectAngle(this.angles[0]);
    },

    selectAngle(angle: CameraAngle | null): void {
      this.selectedAngle = angle;
      if (
        this.selectedProduction != null &&
        this.selectedPerformance != null &&
        this.selectedAngle != null
      ) {
        let selected = {
          production: this.selectedProduction,
          performance: this.selectedPerformance,
          angle: this.selectedAngle,
        };
        this.$store.commit("setSelected", selected);
      }
    },

    goToVideo(): void {
      if (
        this.selectedProduction != null &&
        this.selectedPerformance != null &&
        this.selectedAngle != null
      ) {
        let productionId = this.selectedProduction.id.toString();
        let performanceId = this.selectedPerformance.id.toString();
        let angleId = this.selectedAngle.id.toString();
        this.stopRecurringUpdateTimer(this.syncInterval);
        this.$router.push({
          name: "player",
          params: {
            productionId: productionId,
            performanceId: performanceId,
            angleId: angleId,
          },
        });
      }
    },

    gotoLogin(): void {
      this.$router.push({ name: "login" });
    },

    gotoLogout(): void {
      this.stopRecurringUpdateTimer(this.syncInterval);
      this.$store.commit("logout");
      this.$router.push({ name: "login" });
    },

    sortByName(productions: Array<any>): Array<any> {
      if (productions != null) {
        productions.sort((a: any, b: any) => {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          } else return 0;
        });
      }
      return productions;
    },

    sortByDatesAndSuffix(perfs: Array<Performance>): Array<Performance> {
      if (perfs != null) {
        perfs.sort((a: Performance, b: Performance) => {
          let dateOrder =
            new Date(b.date).getTime() - new Date(a.date).getTime();

          //a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
          if (dateOrder === 0 && a.suffix && b.suffix) {
            return a.suffix < b.suffix ? -1 : 1;
          } else {
            return dateOrder;
          }
        });
      }
      return perfs;
    },

    showErrorImage(ev: Event) {
      if (ev.srcElement != null)
        (
          ev.srcElement as HTMLImageElement
        ).src = require("@/assets/img/missing.jpg");
    },

    startRecurringUpdateTimer() {
      // wenn der syncInterval schon vorhanden ist, dann return
      this.syncInterval = setInterval(() => {
        this.fetchProductionsAndCommit(this.$store.state.loginStatus.userToken);
      }, this.$store.state.timers.databaseRefresh * 1000);
    },
    stopRecurringUpdateTimer(syncInterval: any) {
      clearInterval(syncInterval);
    },

    startSync(ev: Event) {
      fetch(this.$store.state.fileBaseUrl + "/startsync")
        .then((resp) => resp.json())
        .then((data) => {
          if (parseInt(data.status) === 1 || parseInt(data.status) === 2) {
            this.startProgressPolling();
          }
          this.menuIsVisible = false;
        })
        .then(() => {
          if (this.isOfflineMode) this.$router.push({ name: "login" });
          else
            this.fetchProductionsAndCommit(
              this.$store.state.loginStatus.userToken
            );
        })
        .catch((err) =>
          this.$emit("statusChange", {
            isError: true,
            message: "Could not connect to local storage system: " + err,
          })
        );
    },

    startProgressPolling() {
      this.clearPolling();
      this.pollInterval = setInterval(() => {
        fetch(this.$store.state.fileBaseUrl + "/syncprogress")
          .then((resp) => resp.json())
          .then((data) => {
            this.syncIsRunning = data.syncIsRunning;
            this.syncProgress = data.progress;
            if (this.syncIsRunning == false) {
              this.syncNotRunningCounter += 1;
              if (this.syncNotRunningCounter > 4) {
                this.clearPolling();
              }
            } else {
              this.syncNotRunningCounter = 0;
            }
          })
          .catch((error) => {
            this.$emit("statusChange", {
              isError: true,
              message:
                "Could not retrieve status from local file server: " + error,
            });
            this.syncNotRunningCounter += 1;
            if (this.syncNotRunningCounter > 4) {
              this.clearPolling();
            }
          });
      }, 600);
    },

    clearPolling() {
      if (this.pollInterval) clearInterval(this.pollInterval);
    },

    handleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
  },
});
