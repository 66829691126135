var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chapter-container", class: { active: _vm.isActive } },
    [
      _c("img", {
        staticClass: "chapter-image",
        attrs: { src: _vm.thumbnailUrl },
        on: { error: _vm.showErrorImage }
      }),
      _c("div", { staticClass: "chapter-text" }, [
        _c("p", { staticClass: "index" }, [_vm._v(_vm._s(_vm.index) + " /")]),
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.truncatedName))])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }