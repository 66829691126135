var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("div", { staticStyle: { width: "100%", height: "40px" } }),
      _vm._l(_vm.chapters, function(chapter, index) {
        return _c("chapter-item", {
          key: chapter.id,
          ref: "chapterElements",
          refInFor: true,
          attrs: { index: index, chapter: chapter },
          nativeOn: {
            click: function($event) {
              return _vm.chapterClickedHandler($event, chapter, index)
            }
          }
        })
      }),
      _c("div", { staticStyle: { width: "100%", height: "80px" } }),
      _c("div", { staticClass: "close-button", on: { click: _vm.closeTab } }, [
        _c("img", { attrs: { src: require("@/assets/img/cross.svg") } })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }