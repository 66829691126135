var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "videoui" } }, [
      _c(
        "div",
        {
          staticClass: "controlbar",
          class: { minified: _vm.controlBarIsMinified }
        },
        [
          _c("div", { staticClass: "controlbar-button" }, [
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return (function() {
                      return (_vm.controlBarIsMinified = !_vm.controlBarIsMinified)
                    })($event)
                  }
                }
              },
              [
                _c("img", {
                  style: { transform: _vm.arrowTransform },
                  attrs: {
                    src: require("@/assets/img/arrow_down.svg"),
                    width: "50"
                  }
                })
              ]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "controlbar-container",
              class: { minified: _vm.controlBarIsMinified }
            },
            [
              _c("control-button", {
                staticClass: "first-in-line",
                attrs: {
                  name: "skipback",
                  icon: require("../assets/img/sec_left_white.svg"),
                  isTrigger: true
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.skip(-15)
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "play-container",
                  class: { minified: _vm.controlBarIsMinified }
                },
                [
                  !_vm.isPlaying
                    ? _c("img", {
                        staticStyle: {
                          "max-height": "100%",
                          "max-width": "100%"
                        },
                        attrs: { src: require("../assets/img/play.svg") },
                        on: { click: _vm.play }
                      })
                    : _c("img", {
                        staticStyle: {
                          "max-height": "100%",
                          "max-width": "100%"
                        },
                        attrs: { src: require("../assets/img/pause_mod.svg") },
                        on: { click: _vm.pause }
                      })
                ]
              ),
              _c("control-button", {
                attrs: {
                  name: "skipforward",
                  icon: require("../assets/img/sec_right_white.svg"),
                  isTrigger: true
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.skip(15)
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "timeline-container",
                  on: {
                    pointermove: _vm.handleTimelineTouchMove,
                    pointerdown: _vm.handleTimelinePointerDown,
                    pointerup: _vm.handleTimelineTouchEnd
                  }
                },
                [
                  _c("div", { staticClass: "marker-container" }, [
                    _vm.markerPos
                      ? _c("img", {
                          staticClass: "marker",
                          style: {
                            left: (_vm.markerPos / _vm.duration) * 100 + "%"
                          },
                          attrs: { src: require("@/assets/img/marker.svg") },
                          on: {
                            pointerdown: function($event) {
                              $event.stopPropagation()
                              return _vm.jumpToMarker($event)
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  !_vm.controlBarIsMinified
                    ? _c(
                        "div",
                        { staticClass: "chapter-marker-container" },
                        _vm._l(_vm.chapters, function(chapter) {
                          return _c("img", {
                            key: chapter.id,
                            staticClass: "chapter-marker",
                            style: {
                              left: (chapter.start / _vm.duration) * 100 + "%"
                            },
                            attrs: {
                              src: require("@/assets/img/chapter_marker.svg")
                            },
                            on: {
                              pointerdown: function($event) {
                                $event.stopPropagation()
                                return _vm.jumpToChapter($event, chapter)
                              }
                            }
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("input", {
                    staticClass: "timeline",
                    style: _vm.timelineStyle,
                    attrs: {
                      type: "range",
                      min: "0",
                      max: "1",
                      step: "0.0001"
                    },
                    domProps: { value: _vm.timelinePosition }
                  })
                ]
              ),
              _c("control-button", {
                attrs: {
                  name: "loclast",
                  icon: require("../assets/img/loclast_white_small.svg"),
                  isSlider: false,
                  isTrigger: true,
                  activeButton: _vm.activeControlButton
                },
                on: { setactive: _vm.handleSetActiveButton },
                nativeOn: {
                  click: function($event) {
                    return _vm.jumpToLastPlayLocation($event)
                  }
                }
              }),
              _c("control-button", {
                attrs: {
                  name: "marker",
                  icon: require("../assets/img/marker_set.svg"),
                  isSlider: false,
                  isTrigger: true,
                  activeButton: _vm.activeControlButton
                },
                on: { setactive: _vm.handleSetActiveButton },
                nativeOn: {
                  pointerdown: function($event) {
                    return _vm.handleMarkerSetDown($event)
                  }
                }
              }),
              _c(
                "control-button",
                {
                  attrs: {
                    name: "volume",
                    icon: require("../assets/img/sound_white_mod.svg"),
                    isSlider: true,
                    min: 0,
                    max: 100,
                    step: 2,
                    default: 30,
                    controlBarIsMinified: _vm.controlBarIsMinified,
                    unit: "%",
                    activeButton: _vm.activeControlButton
                  },
                  on: {
                    valuechange: _vm.setVolume,
                    setactive: _vm.handleSetActiveButton
                  }
                },
                [
                  _c("arc-slider", {
                    attrs: {
                      isSlider: true,
                      min: 0,
                      max: 100,
                      step: 2,
                      default: 50,
                      controlBarIsMinified: _vm.controlBarIsMinified,
                      unit: "%"
                    }
                  })
                ],
                1
              ),
              _c(
                "control-button",
                {
                  attrs: {
                    name: "rate",
                    icon: require("../assets/img/speed_white.svg"),
                    controlBarIsMinified: _vm.controlBarIsMinified,
                    activeButton: _vm.activeControlButton
                  },
                  on: {
                    valuechange: _vm.changePlaybackRate,
                    setactive: _vm.handleSetActiveButton
                  }
                },
                [
                  _c("arc-slider", {
                    attrs: {
                      isSlider: true,
                      min: 0.5,
                      max: 4,
                      step: 0.5,
                      default: 1,
                      controlBarIsMinified: _vm.controlBarIsMinified,
                      unit: "x"
                    }
                  })
                ],
                1
              ),
              _c(
                "control-button",
                {
                  attrs: {
                    name: "angle",
                    icon: require("../assets/img/view_white.svg"),
                    isSlider: false,
                    controlBarIsMinified: _vm.controlBarIsMinified,
                    activeButton: _vm.activeControlButton
                  },
                  on: { setactive: _vm.handleSetActiveButton }
                },
                [
                  _c("SelectBox", {
                    staticClass: "angle-box",
                    class: { minified: _vm.controlBarIsMinified },
                    attrs: {
                      listItems: _vm.cameraAngles,
                      displayProperty: "name"
                    },
                    on: { change: _vm.changeAngle }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm.controlBarIsMinified
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "block",
                    position: "relative",
                    width: "100%"
                  }
                },
                [
                  _c("control-button", {
                    staticClass: "first-in-line",
                    attrs: {
                      name: "skipback",
                      icon: require("../assets/img/gotomarker_left_mod.svg"),
                      isTrigger: true,
                      isSmall: true
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.jumpToPreviousChapter($event)
                      }
                    }
                  }),
                  _c("div", {
                    staticStyle: {
                      float: "left",
                      margin: "0vw 1vw",
                      position: "relative",
                      top: "-5px",
                      "pointer-events": "none",
                      width: "calc(var(--button-size) + 1vw)",
                      height: "var(--button-size)"
                    }
                  }),
                  _c("control-button", {
                    attrs: {
                      name: "skipback",
                      icon: require("../assets/img/gotomarker_right_mod.svg"),
                      isTrigger: true,
                      isSmall: true
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.jumpToNextChapter($event)
                      }
                    }
                  }),
                  _c("div", { staticClass: "timeline-container" }),
                  _c(
                    "control-button",
                    {
                      attrs: {
                        name: "contrast",
                        icon: require("../assets/img/contrast.svg"),
                        controlBarIsMinified: _vm.controlBarIsMinified,
                        isSmall: true,
                        activeButton: _vm.activeControlButton
                      },
                      on: {
                        valuechange: _vm.updateContrast,
                        setactive: _vm.handleSetActiveButton
                      }
                    },
                    [
                      _c("arc-slider", {
                        attrs: {
                          isSlider: true,
                          min: 0,
                          max: 200,
                          step: 2,
                          default: 100,
                          controlBarIsMinified: _vm.controlBarIsMinified,
                          savedValue: _vm.savedContrast,
                          unit: "%"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "control-button",
                    {
                      attrs: {
                        name: "brightness",
                        icon: require("../assets/img/sun_white_mod.svg"),
                        controlBarIsMinified: _vm.controlBarIsMinified,
                        isSmall: true,
                        activeButton: _vm.activeControlButton
                      },
                      on: {
                        valuechange: _vm.updateBrightness,
                        setactive: _vm.handleSetActiveButton
                      }
                    },
                    [
                      _c("arc-slider", {
                        attrs: {
                          controlBarIsMinified: _vm.controlBarIsMinified,
                          isSlider: true,
                          min: 0,
                          max: 200,
                          step: 2,
                          default: 100,
                          savedValue: _vm.savedBrightness,
                          unit: "%"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.controlBarIsMinified
            ? _c(
                "p",
                {
                  staticStyle: { "pointer-events": "none" },
                  attrs: { id: "time-string" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.formattedTime(_vm.positionInTime)) +
                      " | " +
                      _vm._s(_vm.formattedTime(_vm.duration)) +
                      "\n      "
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }