var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-container", class: { error: _vm.isError } },
    [
      _c("p", [_vm._v(_vm._s(_vm.isError ? "ERROR" : "INFO"))]),
      _c("h3", [_vm._v(_vm._s(_vm.message))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }