interface Number {
  map(
    this: number,
    in_min: number,
    in_max: number,
    out_min: number,
    out_max: number
  ): number;
  clamp(this: number, min: number, max: number): number;
  toHHMMSS(this: number): string;
}

Number.prototype.map = function(
  this: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
): number {
  return ((this - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

Number.prototype.clamp = function(
  this: number,
  min: number,
  max: number
): number {
  return this < min ? min : this > max ? max : this;
};

Number.prototype.toHHMMSS = function() {
  let sec_num = this; // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  let hourString = hours.toString();
  let minutesString = minutes.toString();
  let secondsString = Math.trunc(seconds).toString();

  if (hours < 10) {
    hourString = "0" + hours;
  }
  if (minutes < 10) {
    minutesString = "0" + minutes;
  }
  if (seconds < 10) {
    secondsString = "0" + Math.trunc(seconds);
  }
  return hourString + ":" + minutesString + ":" + secondsString;
};

// const timeout = function (url: string, options: any, timeout: number) {
//     // var controller = new AbortController();
//     // var signal = controller.signal;
//     // options.signal = signal;

//     return new Promise<Response>(function(resolve, reject) {
//         setTimeout(function() {
//             console.log("timeout");
//             // controller.abort();
//             reject(new Error("request timeout"));
//         }, timeout);
//         console.log("fetching");
//         fetch(url,options).then(resolve, reject);
//     });

// }

interface Window {
  fetchWithTimeout(
    url: string,
    options: any,
    timeout: number
  ): Promise<Response>;
}

window.fetchWithTimeout = function(url: string, options: any, timeout: number) {
  var controller = new AbortController();
  var signal = controller.signal;
  options.signal = signal;

  return new Promise<Response>(function(resolve, reject) {
    setTimeout(function() {
      controller.abort();
      reject(new Error("request timeout"));
    }, timeout);
    fetch(url, options).then(resolve, reject);
  });
};
