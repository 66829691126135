















import Vue from "vue";
import { Chapter } from "@/models";
// import {baseUrl, altBaseUrl} from '@/networkConfig'
export default Vue.extend({
  data() {
    return {
      isActive: false as Boolean,
      thumbnailUrl: "" as String,
    };
  },
  props: {
    chapter: {},
    index: Number,
  },
  mounted: function () {
    this.setThumbnailUrl(this.chapter);
  },
  methods: {
    getAvailablePath(url: any, fileUrl: any) {
      return new Promise(function (resolve, reject) {
        fetch(url + fileUrl, { method: "HEAD" })
          .then((res) => {
            if (res.status == 200) resolve(url + fileUrl);
            reject({ status: res.status });
          })
          .catch((err) => {
            reject({ error: err });
          });

        // this.src = streamUrl + fileUrl;
        //     console.log(err);
        //     resolve(streamUrl + fileUrl);
      });
    },

    setThumbnailUrl(chap: any) {
      if (chap.previewImage != null) {
        let url = chap.previewImage.replace(
          this.$store.state.networkConfig.altBaseUrl,
          ""
        );
        this.getAvailablePath(this.$store.state.fileBaseUrl, url)
          .then((path) => (this.thumbnailUrl = path as string))
          .catch(() => {
            this.thumbnailUrl = this.$store.state.networkConfig.baseUrl + url;
          });
      } else this.thumbnailUrl = "";
    },

    setActive(value: boolean) {
      this.isActive = value;
    },
    showErrorImage(ev: Event) {
      console.log(ev);
      this.thumbnailUrl = require("@/assets/img/missing.jpg");
    },
  },
  computed: {
    truncatedName(): string {
      let chap = this.chapter as Chapter;

      let length = 30;

      let ending = "...";

      if (chap.name.length > length) {
        return chap.name.substring(0, length - ending.length) + ending;
      } else {
        return chap.name;
      }
    },
  },
});
