import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import SelectionScreen from "./views/SelectionScreen.vue";
import Player from "./views/Player.vue";
import Login from "./views/Login.vue";
import Store from "./store";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/",
      alias: "/select",
      name: "selection_screen",
      component: SelectionScreen,
      // route requires Login
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/player/:productionId/:performanceId/:angleId",
      name: "player",
      component: Player,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue"),
    },
  ],
});

// Check if user is logged in to access a route, otherwise got to login page
router.beforeEach(async(to, from, next) => {
  await (store as any).restored;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.loginStatus.isLoggedIn) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
