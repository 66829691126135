var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.listItems
      ? _c(
          "ul",
          { staticClass: "select-list" },
          _vm._l(_vm.listItems, function(item) {
            return _c(
              "li",
              {
                key: item["id"],
                class: [
                  _vm.selectionFromParent != null &&
                  _vm.selectionFromParent.id === item.id
                    ? "selected"
                    : "",
                  "select-item"
                ],
                attrs: { value: item["id"] },
                on: {
                  mousedown: function($event) {
                    return _vm.selectItem(item)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.byString(item, _vm.displayProperty)) +
                    "\n    "
                )
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }