







import Vue from "vue";
export default Vue.extend({
  props: {
    title: String,
    date: String,
    angle: String,
  },
});
