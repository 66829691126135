

























import Vue from "vue";
import { mount } from "@vue/test-utils";
import "hammerjs";
import _ from "lodash";
import { Chapter, CameraAngle } from "@/models";
import Hls from "hls.js";

const mapArray: number[] = [1 / 60, 1 / 30, 1 / 15, 1 / 4, 1 / 2, 1, 2];

export default Vue.extend({
  name: "VideoPlayer",
  data() {
    return {
      masterTime: 0 as number,
      videoEl: null as HTMLVideoElement | null,
      volume: 1 as number,
      markerPos: null as number | null,
      wasPlaying: false as boolean,
      lastPlayedFrom: 0 as number,
      speed: 1 as number,
      isUIVisible: true as boolean,
      zoomLevel: 1 as number,
      currentZoom: 1 as number,
      translateX: 0 as number,
      translateY: 0 as number,
      positionOffset: {
        x: 0 as number,
        y: 0 as number,
      },
      scrubIntervalIndex: 0 as number,
      scrubTouchStartingIndex: 0 as number,
      controlBarIsMinified: true as boolean,
      currentChapter: {} as Chapter,
      audioCtx: null as null | AudioContext,
      hls: {} as Hls,
    };
  },
  props: {
    src: String,
    chapters: Array as () => Chapter[],
    angle: Object as () => CameraAngle,
  },

  computed: {
    videoTransform(): string {
      return `scale(${this.currentZoom}) translate(${this.translateX}px,${this.translateY}px)`;
    },

    scrubInterval(): number {
      return mapArray[this.scrubIntervalIndex];
    },

    offset(): number {
      return (this.angle as CameraAngle).offsetFromMaster;
    },

    brightness(): number {
      if (this.$store.state.angleSettings[this.angle.id])
        return this.$store.state.angleSettings[this.angle.id].brightness / 100;
      else return 1;
    },

    contrast(): number {
      if (this.$store.state.angleSettings[this.angle.id])
        return this.$store.state.angleSettings[this.angle.id].contrast;
      else return 100;
    },

    // masterTime(): number {
    //     console.log(this.position+this.angle.offsetFromMaster);
    //     return this.position - this.angle.offsetFromMaster;
    // }
    // progress() : number {
    //     if(this.videoEl)
    //         return this.videoEl.currentTime / this.videoEl.duration;
    //     else
    //         return 0;
    // }
  },

  watch: {
    angle(to, from) {
      if (this.videoEl) {
        this.videoEl.addEventListener("loadeddata", this.updatePositionDelayed);
      }
    },
    src(to: string, from: string) {
      if (this.videoEl) {
        if (
          this.videoEl.canPlayType("application/vnd.apple.mpegurl") ||
          !to.endsWith(".m3u8")
        ) {
          this.videoEl.src = to;
        } else {
          if (Hls.isSupported() && to != "") {
            console.log("loading hls", to);
            this.hls.detachMedia();
            this.hls.attachMedia(this.videoEl);
            this.hls.loadSource(to);
          }
        }
      }
    },
  },

  mounted: function () {
    this.videoEl = this.$refs.vid as HTMLVideoElement;
    const touchLayer = this.$refs.touchLayer as HTMLDivElement;
    this.setupTouchListeners(touchLayer);
    this.lastPlayedFrom = this.offset;

    //setup HLS support
    this.hls = new Hls();
    this.hls.attachMedia(this.videoEl);

    // create an audio context and hook up the video element as the source
    this.audioCtx = new AudioContext();
    let source = this.audioCtx.createMediaElementSource(this.videoEl);

    // create a gain node
    let gainNode = this.audioCtx.createGain();
    gainNode.gain.value = 2; // double the volume
    source.connect(gainNode);

    // connect the gain node to an output destination
    gainNode.connect(this.audioCtx.destination);
  },

  destroyed: function () {
    if (this.audioCtx) this.audioCtx.close();
  },

  methods: {
    updatePositionDelayed() {
      if (this.videoEl) {
        this.$emit("videoloaded", {});
        this.videoEl.removeEventListener(
          "loadeddata",
          this.updatePositionDelayed
        );
      }
    },

    setupTouchListeners(touchLayer: HTMLDivElement) {
      const hammer = new Hammer.Manager(touchLayer, {});

      let singleTap = new Hammer.Tap({ event: "singletap", taps: 1 });
      let doubleTap = new Hammer.Tap({
        event: "doubletap",
        taps: 2,
        posThreshold: 30,
      });

      hammer.add([doubleTap, singleTap]);
      doubleTap.recognizeWith(singleTap);
      singleTap.requireFailure(doubleTap);

      hammer.on("singletap", this.onSingleTap);
      hammer.on("doubletap", this.onDoubleTap);

      let scrubMove = new Hammer.Pan({
        event: "scrubmove",
        threshold: 40,
        direction: Hammer.DIRECTION_HORIZONTAL,
        pointers: 1,
      });
      let scrubVertical = new Hammer.Pan({
        event: "scrubvert",
        threshold: 40,
        direction: Hammer.DIRECTION_VERTICAL,
        pointers: 1,
      });
      hammer.add(scrubMove);
      hammer.add(scrubVertical);
      scrubVertical.recognizeWith(scrubMove);
      hammer.on("scrubmovestart", this.onPanStart);
      hammer.on("scrubmoveend", this.onPanEnd);

      // hammer.on('scrubvert', this.onScrubVertical);
      hammer.on("scrubvertstart", this.onScrubVerticalStart);

      hammer.on("scrubmove", this.onScrubMove);

      let twoFingerPan = new Hammer.Pan({ event: "twofingerpan", pointers: 2 });
      hammer.add(twoFingerPan);

      let pinchZoom = new Hammer.Pinch({ event: "pinchzoom" });
      pinchZoom.recognizeWith(twoFingerPan);
      hammer.add(pinchZoom);
      hammer.on("pinchzoomstart", this.onPinchZoomStart);
      // pinchZoom.requireFailure(twoFingerPan);
      hammer.on("pinchzoom", this.onPinchZoom);

      hammer.on("twofingerpan", this.onTwoFingerPan);
      hammer.on("twofingerpanstart", this.onTwoFingerPanStart);
    },

    onTwoFingerPan(ev: HammerInput) {
      this.translateX = (this.positionOffset.x + ev.deltaX).clamp(
        -500 * (this.currentZoom - 1),
        500 * (this.currentZoom - 1)
      );
      this.translateY = (this.positionOffset.y + ev.deltaY).clamp(
        -500 * (this.currentZoom - 1),
        500 * (this.currentZoom - 1)
      );
    },

    onTwoFingerPanStart(ev: HammerInput) {
      this.positionOffset.x = this.translateX;
      this.positionOffset.y = this.translateY;
    },

    onPinchZoom(ev: HammerInput) {
      this.currentZoom = (this.zoomLevel * ev.scale * 0.5).clamp(1, 3);

      if (this.currentZoom == 1) {
        this.translateX = 0;
        this.translateX = 0;
        this.positionOffset.x = 0;
        this.positionOffset.y = 0;
      }

      console.log("center of touch" + ev.center);

      this.$emit("zoomchange", this.currentZoom);
    },

    onPinchZoomStart(ev: HammerInput) {
      this.zoomLevel = this.currentZoom;
    },

    onPanEnd(ev: HammerInput) {
      if (this.wasPlaying && this.videoEl) this.videoEl.play();
    },

    onPanStart(ev: HammerInput) {
      if (this.videoEl) {
        this.wasPlaying = !this.videoEl.paused;
        this.videoEl.pause();
      }

      this.scrubTouchStartingIndex = this.scrubIntervalIndex;
    },

    onScrubVertical(ev: HammerInput) {
      var threshold = 10;
      if (Math.abs(ev.velocityY) > Math.abs(ev.velocityX)) {
        let mappedDelta = Math.round(ev.deltaY * 0.02);
        this.scrubIntervalIndex = (
          this.scrubTouchStartingIndex - mappedDelta
        ).clamp(0, mapArray.length - 1);
      }
    },

    onScrubVerticalStart(ev: HammerInput) {
      this.scrubTouchStartingIndex = this.scrubIntervalIndex;
    },

    onScrubMove(ev: HammerInput) {
      if (this.videoEl) {
        if (Math.abs(ev.velocityX) > Math.abs(ev.velocityY)) {
          if (ev.direction == Hammer.DIRECTION_LEFT)
            this.videoEl.currentTime -= this.scrubInterval;
          else if (ev.direction == Hammer.DIRECTION_RIGHT)
            this.videoEl.currentTime += this.scrubInterval;
        }
      }
    },

    onSingleTap(ev: HammerInput) {
      // this.playPause();
      this.isUIVisible = !this.isUIVisible;
      this.$emit("uidisplaychange", this.isUIVisible);
    },

    onDoubleTap(ev: HammerInput) {
      this.playPause();
    },

    videoTimeUpdated(ev: Event) {
      if (this.videoEl) {
        this.masterTime = this.videoEl.currentTime + this.offset;
        this.chapterChangeCheck(this.masterTime);
        this.$emit("timeupdate", this.masterTime);
      }
    },

    chapterChangeCheck(currentTime: number) {
      (this.chapters as Chapter[]).forEach((chapter, index) => {
        if (currentTime >= chapter.start) {
          if (
            (index < this.chapters.length - 1 &&
              currentTime < this.chapters[index + 1].start) ||
            index == this.chapters.length - 1
          ) {
            if (this.currentChapter != chapter) {
              this.currentChapter = chapter;
              this.$emit("chapterChange", { chapter, index });
            }
          }
        }
      });
    },

    onSeekCompleted(): void {
      if (this.videoEl) {
        this.lastPlayedFrom = this.masterTime;
        this.videoEl.removeEventListener("seeked", this.onSeekCompleted);
      }
    },

    playPause() {
      if (this.videoEl) {
        if (this.videoEl.paused) {
          if (this.videoEl.seeking) {
            this.videoEl.addEventListener("seeked", this.onSeekCompleted);
          } else {
            this.lastPlayedFrom = this.masterTime;
          }
          this.videoEl.play();
        } else this.videoEl.pause();
      }
    },
    jumpToChapter(ev: MouseEvent, chapter: Chapter): void {
      if (this.videoEl) {
        this.videoEl.currentTime = chapter.start - this.offset;
      }
    },
    setPosition(pos: number) {
      if (this.videoEl) {
        this.videoEl.currentTime = pos - this.offset;
      }
    },
    jumpToLastPlayLocation() {
      if (this.videoEl)
        this.videoEl.currentTime = this.lastPlayedFrom - this.offset;
    },
    setPlaybackRate(rate: number) {
      if (this.videoEl) this.videoEl.playbackRate = rate;
    },
    setVolume(vol: number) {
      if (this.videoEl) this.videoEl.volume = vol;
    },
    handlePlaystateChange(playing: boolean) {
      if (this.videoEl) this.$emit("playstatechange", playing);
    },
    play() {
      if (this.videoEl) {
        this.videoEl.play();
        this.lastPlayedFrom = this.videoEl.currentTime + this.offset;
      }
    },
    pause() {
      if (this.videoEl) this.videoEl.pause();
    },

    handleLoadingError(ev: Event) {
      this.$emit("videoerror", ev);
    },

    handleLoadingSuccess(ev: Event) {
      this.$emit("videoload", ev);
    },
  },
});
