var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-button", class: { small: _vm.isSmall } },
    [
      _c(
        "div",
        {
          staticClass: "button",
          class: { small: _vm.isSmall, open: _vm.isOpen },
          on: { mousedown: _vm.handleButtonClick }
        },
        [_c("img", { class: { small: _vm.isSmall }, attrs: { src: _vm.icon } })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen && !_vm.isTrigger,
              expression: "isOpen && !isTrigger"
            }
          ],
          staticClass: "control-container",
          class: { minified: _vm.controlBarIsMinified }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }