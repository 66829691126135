import { render, staticRenderFns } from "./StatusPopup.vue?vue&type=template&id=bea0ce4c&scoped=true&"
import script from "./StatusPopup.vue?vue&type=script&lang=ts&"
export * from "./StatusPopup.vue?vue&type=script&lang=ts&"
import style0 from "./StatusPopup.vue?vue&type=style&index=0&id=bea0ce4c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bea0ce4c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/p00353_hh-ballett/client-player/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bea0ce4c')) {
      api.createRecord('bea0ce4c', component.options)
    } else {
      api.reload('bea0ce4c', component.options)
    }
    module.hot.accept("./StatusPopup.vue?vue&type=template&id=bea0ce4c&scoped=true&", function () {
      api.rerender('bea0ce4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StatusPopup.vue"
export default component.exports