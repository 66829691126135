



















import Vue from "vue";
import "@/extensions";

export default Vue.extend({
  data() {
    return {
      isOpen: false as boolean,
    };
  },
  props: {
    name: String,
    icon: {},
    isTrigger: Boolean,
    isSmall: Boolean,
    controlBarIsMinified: Boolean,
    activeButton: String,
  },

  watch: {
    activeButton(to: String, from: String) {
      if (to != this.name) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    handleButtonClick(ev: MouseEvent) {
      if (!this.isTrigger) {
        this.isOpen = !this.isOpen;
        if (this.isOpen) this.$emit("setactive", this.name);
      } else {
        this.$emit("setactive", this.name);
      }
    },
  },
});
