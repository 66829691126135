





















import Vue from "vue";

export default Vue.extend({
  name: "SelectBox",
  data() {
    return {
      selectedItem: null as unknown,
      test: "test" as String,
    };
  },

  props: {
    listItems: {},
    displayProperty: {
      type: String,
    },
    selectionFromParent: {},
  },

  methods: {
    selectItem(item: any) {
      this.selectedItem = item;
      this.$emit("change", this.selectedItem);
    },

    byString(o: any, s: string): Object {
      s = s.replace(/^\./, ""); // strip a leading dot
      let a = s.split(".");

      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];

        o = o[k];
      }
      return o as Object;
    },
  },
});
