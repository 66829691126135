<template>
  <div style="width: 300px">
    <img
      class="no_darkmode spinner"
      src="@/assets/img/spinner.svg"
      style="width: 80px; position: absolute; top: 60px; left: 120px"
    />
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "Spinner",
  data() {
    return {};
  },
});
</script>

<style scoped>
.spinner {
  animation-duration: 2s;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>