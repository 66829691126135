
























































































































import Vue from "vue";
import {
  Production,
  Performance,
  CameraAngle,
  Chapter,
  VideoObject,
} from "@/models";
import VideoPlayer from "@/components/VideoPlayer.vue";
import ChapterTab from "@/components/ChapterTab.vue";
import ControlBar from "@/components/ControlBar.vue";
import InfoBar from "@/components/InfoBar.vue";
import Spinner from "@/components/Spinner.vue";
import moment from "moment";
// import { baseUrl, altBaseUrl } from '@/networkConfig'
// import SettingsTab from '@/components/SettingsTab.vue'
import "hammerjs";

import _ from "lodash";
export default Vue.extend({
  name: "Player",
  components: {
    VideoPlayer,
    ChapterTab,
    ControlBar,
    InfoBar,
    Spinner,
    // SettingsTab,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showChapters: false as Boolean,
      showTimeline: true as Boolean,
      showSettings: false as Boolean,
      showUI: true as Boolean,
      videotime: 0 as number,
      videoIsPlaying: false as Boolean,
      currentZoom: 100 as number,
      showErrorOverlay: false as boolean,
      showSpinner: true as boolean,
      src: "" as String,
    };
  },
  props: {
    angleId: {
      type: String,
    },
    performanceId: {
      type: String,
    },
    productionId: {
      type: String,
    },
    time: {
      type: String,
    },
    resumePlaying: {
      type: String,
    },
  },

  mounted: function () {
    const chapterTouch = this.$refs.chaptertouch as HTMLDivElement;
    this.setupChapterSwipe(chapterTouch);
    this.setAvailableVideoSrc();
    this.playSoundBarWakeUpSound();
  },

  computed: {
    production(): Production {
      return this.$store.state.entities.productions[
        this.productionId
      ] as Production;
    },
    performance(): Performance {
      let performance =
        this.$store.state.entities.performances[this.performanceId];
      performance.dateString = moment(new Date(performance.date)).format(
        "DD.MM.YYYY"
      );
      if (performance.suffix && performance.suffix !== "") {
        performance.dateString += `-${performance.suffix}`;
      }
      return this.$store.state.entities.performances[
        this.performanceId
      ] as Performance;
    },
    angle(): CameraAngle {
      return this.$store.state.entities.cameraAngles[
        this.angleId
      ] as CameraAngle;
    },
    chapters(): Chapter[] {
      let chapters: Array<Chapter> = [];
      if (this.performance)
        this.performance.chapters.forEach((chapterId) => {
          chapters.push(this.$store.state.entities.chapters[chapterId]);
        });

      return chapters;
    },
  },

  watch: {
    "$route.params"(to, from) {
      this.videoIsPlaying = false;
    },
    angleId(to, from) {
      this.setAvailableVideoSrc();
    },
    src(to, from) {
      this.showErrorOverlay = false;
      this.showSpinner = false;
    },
  },

  methods: {
    getAvailablePath(url: any, fileUrl: any) {
      // console.log("new path: " +url+fileUrl);
      return new Promise(function (resolve, reject) {
        fetch(url + fileUrl, { method: "HEAD" })
          .then((res) => {
            if (res.status == 200) resolve(url + fileUrl);
            reject({ status: res.status });
          })
          .catch((err) => {
            reject({ error: err });
          });

        setTimeout(() => {
          reject({ error: "timeout" });
        }, 500);
      });
    },

    setAvailableVideoSrc() {
      if (this.angle.videos.length > 0) {
        let localAvailable = true;

        for (let id = 0; id < this.angle.videos.length; id++) {
          let videoId: number = this.angle.videos[id] as unknown as number;
          let videoObj = this.$store.state.entities.videos[videoId];

          if (videoObj.type == "SRC") {
            //always expects at least one SRC video object
            let videoPath = videoObj.url as String;
            videoPath = videoPath.replace(
              this.$store.state.networkConfig.baseUrl,
              ""
            );
            this.getAvailablePath(this.$store.state.fileBaseUrl, videoPath)
              .then((path) => (this.src = path as String))
              .catch(() => {
                this.getAvailablePath(
                  this.$store.state.networkConfig.baseUrl,
                  videoPath
                )
                  .then((path) => (this.src = path as String))
                  .catch((err) => {
                    console.warn(
                      "file not locally or in internal net, falling back to streaming"
                    );
                    localAvailable = false;
                    let streamingVideoId = this.angle.videos.find(
                      (id) =>
                        this.$store.state.entities.videos[id].type == "STR"
                    ) as number | undefined;
                    if (streamingVideoId)
                      this.src =
                        this.$store.state.entities.videos[streamingVideoId].url;
                    else
                      console.error(
                        "no local file available AND no stream available"
                      );
                    console.log("video source should be set to", this.src);
                  });
                // this.src = this.$store.state.networkConfig.baseUrl + videoPath
              });
          }
        }

        // let videoObj = this.$store.state.entities.videos[videoId];
        // console.log("videoObj", videoObj);
        // let videoPath = videoObj.file as String;
        // if(videoObj.type == "STR") {
        //     this.src = videoPath
        //     // TODO: try to convert stream url into a "local file url" and see if that is available, if not, try the stream.

        //     // let localPathFromStreamingPath = videoPath.replace("_streaming_medium", "");
        //     // this.getAvailablePath(videoPath, "")
        //     //     .then(path => {
        //     //         this.src = path as String;
        //     //     })
        // }
        // else {
        //     videoPath = videoPath.replace(this.$store.state.networkConfig, "");
        //     this.getAvailablePath(this.$store.state.fileBaseUrl, videoPath)
        //     .then(path => this.src = path as String)
        //     .catch(() => {
        //         this.src = this.$store.state.networkConfig.baseUrl + videoPath
        //     });
        // }
      }
    },
    chapterJumpHandler(chapter: Chapter) {
      (this.$refs.videoplayer as any).jumpToChapter(null, chapter);
    },
    chapterChangeHandler(ev: any) {
      (this.$refs.chaptertab as any).setChapterActive(ev.chapter, ev.index);
    },
    setupChapterSwipe(el: HTMLDivElement) {
      let hammer = new Hammer.Manager(el, {});
      let swiperight = new Hammer.Swipe({
        event: "swiperight",
        direction: Hammer.DIRECTION_RIGHT,
      });
      hammer.add(swiperight);
      hammer.on("swiperight", () => (this.showChapters = true));
      // hammer.on('tap', () => this.showChapters = true);        //handled via click handler in markup
    },
    handleSetPosition(pos: number) {
      (this.$refs.videoplayer as any).setPosition(pos);
    },
    handleLocLast() {
      (this.$refs.videoplayer as any).jumpToLastPlayLocation();
    },
    handleSetRate(rate: number) {
      (this.$refs.videoplayer as any).setPlaybackRate(rate);
    },
    handleSetVolume(vol: number) {
      (this.$refs.videoplayer as any).setVolume(vol);
    },
    handleTimeUpdate(time: number) {
      this.videotime = time;
    },
    handlePlayPause(isPlaying: boolean) {
      this.videoIsPlaying = isPlaying;
    },
    handleVideoLoaded() {
      this.showSpinner = false;
      this.handleSetPosition(
        this.$store.state.productionTime[this.productionId]
      );
      console.log("resume playing", this.resumePlaying);
      if (this.resumePlaying == "true") {
        console.log("resume playing now");
        this.setPlay();
      }
      // console.log("video loaded");
    },
    setPlay() {
      (this.$refs.videoplayer as any).play();
    },
    setPause() {
      (this.$refs.videoplayer as any).pause();
    },
    handleUIDisplay(show: boolean) {
      if (this.showTimeline || this.showChapters || this.showSettings) {
        this.showTimeline = false;
        this.showChapters = false;
        this.showSettings = false;
      } else {
        this.showTimeline = true;
      }
    },
    handleZoomChange(zoom: number) {
      this.currentZoom = Math.round(zoom * 100);
    },

    handleVideoLoadingError(ev: Event) {
      // this.showSpinner = true;
      if (this.src != "") this.showErrorOverlay = true;
    },

    playSoundBarWakeUpSound() {
      const player = this.$refs.wakeUpSound as HTMLAudioElement;
      player.currentTime = 0;
      player.play();
      console.log("playing wakeup sound");
    },
  },
});
