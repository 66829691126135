























































import Vue from "vue";
import VueTouchKeyboard from "vue-touch-keyboard";
import { LogInIcon, DatabaseIcon } from "vue-feather-icons";
// import { apiUrl, loginEndpoint } from "@/networkConfig";

Vue.use(VueTouchKeyboard);

export default Vue.extend({
  data() {
    return {
      username: "" as String,
      password: "" as String,

      inputEv: null as Event | null,
      focusEv: null as Event | null,
      theme: "simple-keyboard myTheme1",
      inputTarget: null as HTMLInputElement | null,
      layout: "normal",
      input: null,
      isWindows: false,
      options: {
        useKbEvents: false,
        preventClickEvent: false,
      },

      defaultVisible: false,
      visible: false,
    };
  },
  components: {
    LogInIcon,
  },
  mounted: function () {
    this.isWindows = window.navigator.userAgent.indexOf("Windows") >= 0;
    fetch(this.$store.state.fileBaseUrl + "/credentials")
      .then((resp) => resp.json())
      .then((obj) => {
        this.$store.commit("setDefaultUser", obj);
        this.defaultVisible = true;
      })
      .catch((err) =>
        console.error("not on a ballet-device, no device login available")
      );
  },

  methods: {
    loginUser(ev: Event) {
      let userData = { username: this.username, password: this.password };
      this.login(userData);
    },

    loginDefault() {
      this.login(this.$store.state.defaultUser, true);
    },

    login(user: any, isDefaultLogin = false) {
      fetch(
        this.$store.state.networkConfig.apiUrl +
          this.$store.state.networkConfig.loginEndpoint,
        {
          body: JSON.stringify(user),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error(res.status.toString());
        })
        .then((obj) => {
          this.$store.commit("login", {
            username: user.username,
            token: obj.access,
            isDefaultLogin: isDefaultLogin,
          });
          this.$router.push({ name: "selection_screen" });
        })
        .catch((err) => {
          if (isDefaultLogin === true) {
            this.$store.commit("login", {
              username: user.username,
              token: null,
              isDefaultLogin: true,
            });
            this.$router.push({ name: "selection_screen" });
          } else
            this.$emit("statusChange", {
              isError: true,
              message: "login failed\n" + err,
            });
        });
    },

    show(e: any) {
      this.visible = true;
      this.input = e.target;
      this.layout = e.target.dataset.layout;
    },
    accept(text: any) {
      this.hide();
    },
    hide() {
      this.visible = false;
    },
  },
});
