



















import Vue, { Component } from "vue";

import { Chapter } from "@/models";
import ChapterItem from "@/components/ChapterItem.vue";

export default Vue.extend({
  name: "ChapterTab",

  data() {
    return {
      activeChapter: {
        component: null as any,
        data: null as null | Chapter,
      },
    };
  },
  components: {
    ChapterItem,
  },

  props: {
    chapters: {},
    isShowing: Boolean,
  },

  watch: {
    isShowing(to, from) {
      if (to == true && this.activeChapter.component) {
        this.activeChapter.component.$el.scrollIntoView({
          left: 0,
          block: "start",
          behavior: "smooth",
        });
      }
    },
  },

  methods: {
    chapterClickedHandler(ev: MouseEvent, chapter: Chapter, index: number) {
      this.$emit("chapterJump", chapter);
      if (ev.target != null) this.setChapterActive(chapter, index);
    },

    setChapterActive(chapter: Chapter, index: number) {
      if (this.activeChapter.component != null) {
        this.activeChapter.component.setActive(false);
      }
      let vmChapter = (this.$refs.chapterElements as any[])[index];
      vmChapter.setActive(true);
      this.activeChapter.component = vmChapter;
      this.activeChapter.component.$el.scrollIntoView({
        left: 0,
        block: "start",
        behavior: "smooth",
      });
    },
    closeTab() {
      this.$emit("close", {});
    },
  },
});
