var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        key: "one",
        ref: "slider",
        staticClass: "slider-svg",
        class: { minified: _vm.controlBarIsMinified }
      },
      [
        _c("path", {
          staticStyle: { "stroke-width": "2px" },
          attrs: {
            d: _vm.describeArc(
              162,
              162,
              160,
              360 - _vm.arcLength,
              _vm.arcLength
            ),
            stroke: "white",
            fill: "#0a0a0adc"
          }
        }),
        _c("path", {
          staticStyle: { "stroke-width": "10px" },
          attrs: {
            d: _vm.describeArc(162, 162, 150, 360 - _vm.arcLength, _vm.value),
            stroke: "var(--primary-color)",
            fill: "transparent"
          }
        }),
        _c("path", {
          staticStyle: { "stroke-width": "1px" },
          attrs: {
            d: _vm.describeArc(162, 162, 145, 360 - _vm.arcLength, 0),
            stroke: "white",
            fill: "transparent"
          }
        }),
        _c(
          "text",
          {
            staticStyle: { "font-size": "45px", "font-weight": "normal" },
            attrs: { x: "125", y: "125", "text-anchor": "end", fill: "white" }
          },
          [_vm._v("\n      " + _vm._s(_vm.mappedValue) + "\n    ")]
        ),
        _c(
          "text",
          {
            staticStyle: { "font-size": "24px" },
            attrs: { x: "130", y: "125", fill: "white" }
          },
          [_vm._v("\n      " + _vm._s(_vm.unit) + "\n    ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }