










































import Vue from "vue";
import "hammerjs";
import "@/extensions";

export default Vue.extend({
  data() {
    return {
      value: 0,
      startValue: 0,
      arcLength: 86.5,
      mappedValue: "",
    };
  },
  props: {
    name: String,
    icon: {},
    isSlider: Boolean,
    min: Number,
    max: Number,
    step: Number,
    unit: String,
    default: Number,
    controlBarIsMinified: Boolean,
    savedValue: Number,
  },
  mounted: function () {
    this.setupTouchGestures(this.$refs.slider as HTMLElement);
    this.setDefaultValue();
    if (this.savedValue) {
      this.value = this.savedValue.map(this.min, this.max, -this.arcLength, 0);
      //    console.log("updated from saved value");
    }
  },
  watch: {
    value(to, from) {
      if (to != from) {
        let mapVal = this.mapValue(to);
        this.updateValue(mapVal);
        this.mappedValue = mapVal.toLocaleString(
          undefined, // leave undefined to use the browser's locale,
          // or use a string like 'en-US' to override it.
          { maximumFractionDigits: 2 }
        );
      }
    },
    savedValue(to, from) {
      //   console.log("saved value changed");
      this.value = to.map(this.min, this.max, -this.arcLength, 0);
    },
  },

  methods: {
    mapValue(value: number): number {
      return (
        Math.round(
          this.value.map(-this.arcLength, 0, this.min, this.max) / this.step
        ) * this.step
      );
    },
    setupTouchGestures(el: HTMLElement) {
      let hammer = new Hammer(el);
      hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
      hammer.on("panstart", (ev) => {
        this.startValue = this.value;
      });
      hammer.on("panmove", (ev) => {
        this.value = ((ev.deltaX - ev.deltaY) * 0.3 + this.startValue).clamp(
          -this.arcLength,
          0
        );
      });
      hammer.on("tap", (ev) => this.setDefaultValue());
    },
    polarToCartesian(
      centerX: number,
      centerY: number,
      radius: number,
      angleInDegrees: number
    ) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },

    setDefaultValue() {
      if (this.default)
        this.value = this.default.map(this.min, this.max, -this.arcLength, 0);
    },

    describeArc(
      x: number,
      y: number,
      radius: number,
      startAngle: number,
      endAngle: number
    ) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ");

      return d;
    },

    updateValue(value: Number) {
      this.$parent.$emit("valuechange", value);
    },
  },
});
