import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./extensions";

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

//service worker update event hook
var refreshing: boolean;

// at page reload the initialization needs to be set to false to initially load the data
window.addEventListener("beforeunload", function(event) {
  store.commit("setHomepageInitialization", false);
});
navigator.serviceWorker.addEventListener("controllerchange", function() {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});
