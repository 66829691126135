




























import Vue from "vue";
import StatusPopUp from "@/components/StatusPopup.vue";
import { Status } from "@/models.ts";

export default Vue.extend({
  data() {
    return {
      isDarkMode: false as boolean,
      transitionName: "slide-up" as String,
      status: null as null | Status,
      showStatus: false as boolean,
      popupTimeout: undefined as number | undefined,
      logoutTimeout: undefined as number | undefined,
      screenTimeout: undefined as number | undefined,
      viewportRedrawEnforcerVisible: false as boolean,
      screensaverActive: false as boolean,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.status = {message: "hi status", isError: false};
    //   this.showStatus = true
    // }, 5000);

    document.body.addEventListener("pointerdown", this.resetLogoutTimeout);
    document.body.addEventListener("pointerdown", this.resetScreenTimeout);

    document.body.addEventListener("pointermove", this.resetLogoutTimeout);
    document.body.addEventListener("pointermove", this.resetScreenTimeout);

    this.readTimerConfig();
    this.resetLogoutTimeout();
    this.resetScreenTimeout();
    this.initializeViewportRefreshWorkaroundTimer();
  },
  beforeDestroy() {
    document.body.removeEventListener("pointerdown", this.resetLogoutTimeout);
    document.body.removeEventListener("pointerdown", this.resetScreenTimeout);

    document.body.removeEventListener("pointermove", this.resetLogoutTimeout);
    document.body.removeEventListener("pointermove", this.resetScreenTimeout);
  },
  components: {
    StatusPopUp,
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-down" : "slide-up";
    },
  },
  methods: {
    statusHasChanged(newStatus: Status) {
      this.status = newStatus;
      this.showStatus = true;
      if (this.popupTimeout) clearTimeout(this.popupTimeout);
      this.popupTimeout = setTimeout(() => (this.showStatus = false), 3 * 1000);
      newStatus.isError
        ? console.error(newStatus.message)
        : console.log(newStatus.message);
    },

    readTimerConfig() {
      fetch(this.$store.state.fileBaseUrl + "/timers")
        .then((resp) => resp.json())
        .then((obj) => {
          this.$store.commit("setTimers", obj);
        })
        .catch((err) => {
          console.error(
            "unable to fetch timer settings from filehoster, using default values"
          );
          this.$store.commit("setTimers", {
            databaseRefresh: 30,
            screenTimeout: 0,
            autoLogoff: 0,
          });
        });
    },

    resetScreenTimeout() {
      clearTimeout(this.screenTimeout);
      if (this.$store.state.timers.screenTimeout) {
        this.screenTimeout = setTimeout(() => {
          this.screensaverActive = true;
        }, this.$store.state.timers.screenTimeout * 1000);
      }
      this.screensaverActive = false;
    },

    resetLogoutTimeout() {
      clearTimeout(this.logoutTimeout);
      if (this.$store.state.timers.autoLogoff) {
        this.logoutTimeout = setTimeout(() => {
          if (
            !this.$store.state.loginStatus.isDefaultLogin &&
            this.$store.state.loginStatus.isLoggedIn
          ) {
            this.$store.commit("logout");
            this.$router.push({ name: "login" });
          }
          this.resetLogoutTimeout();
        }, this.$store.state.timers.autoLogoff * 1000);
      }
    },

    initializeViewportRefreshWorkaroundTimer() {
      setInterval(() => {
        this.viewportRedrawEnforcerVisible =
          !this.viewportRedrawEnforcerVisible;
      }, 1000);
    },
  },
});
