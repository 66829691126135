var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "300px" } }, [
      _c("img", {
        staticClass: "no_darkmode spinner",
        staticStyle: {
          width: "80px",
          position: "absolute",
          top: "60px",
          left: "120px"
        },
        attrs: { src: require("@/assets/img/spinner.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }