






























































































































































































































































import Vue from "vue";
import { Chapter, CameraAngle, Production, Performance } from "@/models";
import ControlButton from "@/components/ControlButton.vue";
import { duration } from "moment";
import hammer from "hammerjs";
import "@/extensions";
import SelectBox from "@/components/SelectBox.vue";
import ArcSlider from "@/components/ArcSlider.vue";
// import { setTimeout } from 'timers';

export default Vue.extend({
  data() {
    return {
      volume: 1 as number,
      wasPlaying: null as boolean | null,
      markerPos: 0 as number,
      controlBarIsMinified: true as boolean,
      brightnessValue: 1 as number,
      contrastValue: 1 as number,
      activeControlButton: "" as String,
      shouldSetMarker: false as Boolean,
      savedBrightness: null as null | number,
      savedContrast: null as null | number,
      pointerIsDown: false as boolean,
      positionInTime: 0 as number,
    };
  },
  components: {
    ControlButton,
    SelectBox,
    ArcSlider,
  },
  watch: {
    volume(to: number, from: number) {
      this.setVolume(to);
    },
    position(to: number, from: number) {
      this.positionInTime = to;
    },
  },
  props: {
    isPlaying: Boolean,
    chapters: Array as () => Chapter[],
    production: Object as () => Production,
    performance: Object as () => Performance,
    angle: Object as () => CameraAngle,
    position: Number,
  },
  mounted: function () {
    if (this.$store.state.angleSettings[this.angle.id]) {
      this.savedBrightness =
        this.$store.state.angleSettings[this.angle.id].brightness;
      this.savedContrast =
        this.$store.state.angleSettings[this.angle.id].contrast;
      // this.savedBrightness =
    }
  },

  computed: {
    duration(): number {
      let dur = 0;
      dur = this.performance.masterCameraAngle.duration;
      // if(this.performance.masterCameraAngle.videos.length > 0) {
      //     dur = this.performance.masterCameraAngle.videos[0].duration;
      // }
      return dur;
    },
    cameraAngles(): Array<CameraAngle> {
      let angles: Array<CameraAngle> = [];
      if (this.performance.cameraAngles != null) {
        this.performance.cameraAngles.forEach((angleId) => {
          angles.push(this.$store.state.entities.cameraAngles[angleId]);
        });
      }
      return angles;
    },
    timelinePosition(): number {
      // console.log('position: ' + this.position/this.duration * 100);
      if (this.position && this.duration) {
        let pos = this.position / this.duration;
        return pos;
      }
      return 0;
    },

    timelineStyle(): string {
      let pos = this.timelinePosition * 100;
      return `background: linear-gradient(90deg, var(--primary-color), var(--primary-color) ${pos}%, rgb(60,60,60) ${pos}%, rgb(60,60,60))`;
    },

    arrowTransform(): string {
      let mult = this.controlBarIsMinified ? 1 : 0;
      return "rotateX(" + mult * 180 + "deg)";
    },
  },

  methods: {
    handleTimelinePointerDown(ev: PointerEvent): void {
      this.pointerIsDown = true;
      if (this.wasPlaying === null) this.wasPlaying = this.isPlaying;
      this.pause();
      if (ev.target != null) {
        const { left, right } = (
          ev.target as HTMLElement
        ).getBoundingClientRect();
        let pos = (ev.clientX - left) / (right - left);

        this.setPosition(this.duration * pos);
      }
    },

    jumpToLastPlayLocation(ev: MouseEvent): void {
      if (ev.target != null) {
        this.$emit("loclast", {});
      }
    },
    /*
     * optimized for touch
     */
    handleTimelineTouchMove(ev: PointerEvent): void {
      if (ev.target != null) {
        const { left, right } = (
          ev.target as HTMLElement
        ).getBoundingClientRect();

        let pos = (ev.clientX - left) / (right - left);

        if (this.pointerIsDown == true) this.setPosition(this.duration * pos);
      }
    },

    handleTimelineTouchEnd(ev: PointerEvent): void {
      this.pointerIsDown = false;
      if (this.wasPlaying == true) {
        this.play();
      }
      this.wasPlaying = null;
    },

    handleVolumePointerDown(ev: PointerEvent): void {
      if (ev.target != null) {
        const { left, right } = (
          ev.target as HTMLElement
        ).getBoundingClientRect();
        this.setVolume((ev.clientX - left) / (right - left));
      }
    },

    /*
     * optimized for touch
     */
    handleVolumeTouchMove(ev: PointerEvent): void {
      if (ev.target != null) {
        const { left, right } = (
          ev.target as HTMLElement
        ).getBoundingClientRect();
        this.setVolume((ev.clientX - left) / (right - left));
      }
    },

    setMarker(ev: Event): void {
      this.markerPos = this.position;
    },

    jumpToMarker(ev: Event): void {
      if (this.markerPos) this.setPosition(this.markerPos);
      if (this.wasPlaying) {
        this.play();
      }
      this.shouldSetMarker = false;
    },

    jumpToChapter(ev: MouseEvent, chapter: Chapter): void {
      this.setPosition(chapter.start);
    },

    jumpToNextChapter() {
      let chaps: Chapter[] = this.chapters as [];
      for (let i = 0; i < chaps.length - 1; i++) {
        if (
          this.position >= chaps[i].start &&
          this.position <= chaps[i + 1].start
        ) {
          this.jumpToChapter({} as MouseEvent, chaps[(i + 1) % chaps.length]);
        }
      }
    },

    jumpToPreviousChapter() {
      let chaps: Chapter[] = this.chapters as [];
      let activeChapterId = 0;
      for (let i = 1; i < chaps.length; i++) {
        if (this.position > chaps[i].start) {
          // console.log(i);
          // let prevChapter = i == 0 ? chaps[chaps.length-1] : chaps[Math.abs(i-1)%chaps.length];
          // console.log(prevChapter);
          // this.jumpToChapter({} as MouseEvent, prevChapter);
          // return;
          activeChapterId = i;
        }
      }

      let prevChapter =
        activeChapterId == 0
          ? chaps[0]
          : chaps[Math.abs(activeChapterId) % chaps.length];
      this.jumpToChapter({} as MouseEvent, prevChapter);
    },

    pause() {
      this.$emit("pause", {});
    },

    play() {
      this.$emit("play", {});
    },

    setPosition(pos: number) {
      this.positionInTime = pos;
      this.$emit("setposition", pos);
    },

    setVolume(vol: number) {
      this.$emit("setvolume", vol / 100);
    },

    changePlaybackRate(rate: number) {
      this.$emit("setrate", rate);
    },

    changeAngle(angle: CameraAngle) {
      let curPos = 0;
      if (!isNaN(this.position)) curPos = this.position;
      //curPos = curPos + this.angle.offsetFromMaster;
      this.$store.commit("updateProductionTime", {
        productionId: this.production.id,
        time: curPos,
      });
      this.$router.push({
        name: "player",
        params: {
          productionId: this.production.id.toString(),
          performanceId: this.performance.id.toString(),
          angleId: angle.id.toString(),
          resumePlaying: this.isPlaying.toString(),
        },
      });
    },
    skip(amount: number) {
      this.$emit("setposition", this.position + amount);
    },

    formattedTime(seconds: number): string {
      return seconds.toHHMMSS();
    },

    updateSettings(ev?: Event) {
      //this.$emit('settingschange', { brightness: this.brightnessValue, contrast: this.contrastValue });
      this.$store.commit("updateSettings", {
        angleId: this.angle.id,
        settings: {
          brightness: this.brightnessValue,
          contrast: this.contrastValue,
        },
      });
    },

    updateBrightness(value: number) {
      this.brightnessValue = value;
      this.updateSettings();
    },

    updateContrast(value: number) {
      this.contrastValue = value;
      this.updateSettings();
    },

    handleSetActiveButton(name: String) {
      this.activeControlButton = name;
    },

    handleMarkerSetDown(ev: Event) {
      this.wasPlaying = this.isPlaying;
      this.pause();
      this.shouldSetMarker = true;
      if (ev.target != null)
        ev.target.addEventListener("pointerup", this.jumpToMarker);
      setTimeout(() => {
        if (ev.target != null)
          ev.target.removeEventListener("pointerup", this.jumpToMarker);
        if (this.shouldSetMarker) this.setMarker(ev as MouseEvent);
      }, 800);
    },
  },
});
