/// <reference path="../public/appSettings.js" />

import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from 'localforage';
import { Production } from "./models";

// import pieceData from './assets/dummyData.json'
const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
});
Vue.use(Vuex);

var config: any = {
  API_SERVER: process.env.VUE_APP_API_SERVER,
  LOCAL_FILEHOST_URL: process.env.VUE_APP_LOCAL_FILEHOST_URL,
  APP_TITLE: process.env.VUE_APP_TITLE,
  APP_VERSION: process.env.VUE_APP_VERSION,
};

if (appSettings) {
  console.log("Filehoster config used");
  Object.keys(appSettings).forEach((option) => {
    console.log("setting", option);
    config[option] = (appSettings as any)[option];
  });
}

console.log(
  config.APP_VERSION +
    ": " +
    config.APP_TITLE +
    " API_SERVER: " +
    config.API_SERVER
);
// console.log("config", config);

interface State {
  entities: any;
  loginStatus: any;
  angleSettings: any;
  productionTime: any;
  fileBaseUrl: string;
  defaultUser: any;
  userRefresh: string;
  deviceToken: string;
  networkConfig: any;
  timers: any;
  selected: any;
  homepageInitialized: boolean;
}

export default new Vuex.Store<State>({
  state: {
    entities: {
      productions: {},
      performances: {},
      cameraAngles: {},

      chapters: {},
      videos: {},
    },
    angleSettings: {},
    productionTime: {},
    fileBaseUrl: config.LOCAL_FILEHOST_URL,
    loginStatus: {
      isLoggedIn: false,
      username: "",
      userToken: "",
      isDefaultLogin: false,
    },
    defaultUser: {
      username: null,
      password: null,
    },
    userRefresh: "",
    deviceToken: "",
    networkConfig: {
      baseUrl: config.API_SERVER,
      altBaseUrl: config.API_SERVER,
      apiUrl: config.API_SERVER + "/api",
      productionsEndpoint: "/productions/?format=json",
      loginEndpoint: "/token/",
    },
    timers: {
      // values are overridden in App.vue - these are not the default values
      databaseRefresh: 3600,
      autoLogoff: 0,
      screenTimeout: 0,
    },
    selected: {
      production: null,
      performance: null,
      angle: null,
    },
    homepageInitialized: false,
  },

  mutations: {
    setEntities(state, entities) {
      state.entities = entities;
    },
    setHomepageInitialization(state, homepageInitialized) {
      state.homepageInitialized = homepageInitialized;
    },
    setSelected(state, selected) {
      state.selected = selected;
    },
    updateEntities(state, { entities }) {
      // Loop over all kinds of entities we received
      for (let type in entities) {
        for (let entity in entities[type]) {
          const oldObj = (state.entities as any)[type][entity] || {};
          // Merge the new data in the old object
          const newObj = Object.assign(oldObj, entities[type][entity]);
          // Make sure new entities are also reactive
          Vue.set((state.entities as any)[type], entity, newObj);
        }
      }
    },
    updateSettings(state, { angleId, settings }) {
      Vue.set(state.angleSettings, angleId, settings);
    },
    resetSettings(state) {
      state.angleSettings = {};
    },
    updateProductionTime(state, { productionId, time }) {
      Vue.set(state.productionTime, productionId, time);
    },

    setTimers(state, data) {
      state.timers = data;
    },

    setDefaultUser(state, data) {
      state.defaultUser = data;
    },

    login(state, { username, token, isDefaultLogin }) {
      state.loginStatus.username = username;
      state.loginStatus.isLoggedIn = true;
      state.loginStatus.userToken = token;
      state.loginStatus.isDefaultLogin = isDefaultLogin ? true : false;
    },

    logout(state) {
      state.selected.production = null;
      state.selected.performance = null;
      state.selected.angle = null;
      state.entities.productions = {};
      state.entities.performances = {};
      state.entities.angles = {};
      state.loginStatus.username = "";
      state.loginStatus.isLoggedIn = false;
      state.loginStatus.userToken = "";
    },
  },
  plugins: [vuexLocal.plugin],
});
